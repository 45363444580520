<template>
  <div class="form">
    <h2 class="form__title">メンテナンスを行う理由</h2>
    <textarea class="form__memo" v-model="memo" placeholder="メンテナンスを行う理由を記載してください。" @input="$emit('edit', memo)" />
  </div>
</template>

<script>
export default {
  data () {
    return {
      // メンテナンス理由
      memo: ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.form {
  border-bottom: 1px solid $black_color;
  &__title {
    margin-bottom: 10px;
    font-size: 1.2rem;
    line-height: 1.4rem;
  }
  &__memo {
    width: 100%;
    font-size: 1.6rem;
    line-height: 1.9rem;
    resize: none;
    outline: none;
  }
}
</style>
